export const baseUrl =
  process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:3000'

export const cockpitUrl = process.env.NEXT_PUBLIC_COCKPIT_URL || ''

export const siteName = process.env.SITE_NAME || ''

export const isProd = process.env.NODE_ENV === 'production'

export const isDev = process.env.NODE_ENV === 'development'

export const isBrowser = typeof window !== 'undefined'

export const gaTrackId = process.env.NEXT_PUBLIC_GA_TRACK_ID || ''

export const fbPixelId = process.env.NEXT_PUBLIC_FB_PIXEL_ID || ''
